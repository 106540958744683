import "../styles/photography-page.scss";
import mitchellFamily from "../assets/mitchell_family.jpg";

export const PhotographyPage = () => (
  <div className="body-container vert-center flex-column">
    <h2 className="photography-header" style={{ textAlign: "center" }}>
      Photography <br />
      Services
    </h2>
    <div className="photography-container">
      <div className="photography-card-grid">
        <div className="photography-image-wrapper">
          <div className="image-container">
            <img src={mitchellFamily} alt="couple with baby" />
          </div>
        </div>
        <div className="photography-card-content">
          <h3> Some Content </h3>
        </div>
      </div>
      <h3 className="floating-header">
        *for other photography inquiries, please contact me
      </h3>
    </div>
    {/*<div className="pricing-lower-block bg-white">*/}
    {/*  <div className="pricing-block-grid">*/}
    {/*    <div className="col-sm pricing-block-outline">*/}
    {/*      <div className="pricing-block">*/}
    {/*        <img src={mitchellFamily} alt="couple with baby" />*/}
    {/*        <h3 className="h-center">Couples</h3>*/}
    {/*        <div className="pricing-dollar-value">123</div>*/}
    {/*        <button className="primary-button">Contact me</button>*/}
    {/*      </div>*/}
    {/*    </div>*/}
    {/*    <div className="col-sm pricing-block-outline">*/}
    {/*      <div className="pricing-block">*/}
    {/*        <img src={mitchellFamily} alt="couple with baby" />*/}
    {/*        <h3 className="h-center">Weddings</h3>*/}
    {/*        <div className="pricing-dollar-value">456</div>*/}
    {/*        <button className="primary-button">Contact me</button>*/}
    {/*      </div>*/}
    {/*    </div>*/}
    {/*    <div className="col-sm pricing-block-outline">*/}
    {/*      <div className="pricing-block">*/}
    {/*        <img src={mitchellFamily} alt="couple with baby" />*/}
    {/*        <h3 className="h-center">Families</h3>*/}
    {/*        <div className="pricing-dollar-value">789</div>*/}
    {/*        <button className="primary-button">Contact me</button>*/}
    {/*      </div>*/}
    {/*    </div>*/}
    {/*  </div>*/}
    {/*</div>*/}
  </div>
);
