import "../styles/portfolio-page.scss";
import InstagramFeed from "../InstagramFeed.min";
import { useEffect } from "react";

const defaultInstaOptions = {
  username: "maggiemae.photo",
  display_profile: true,
  display_biography: false,
  display_gallery: true,
  display_captions: false,
  max_tries: 8,
  styling: true,
  items: 12,
  items_per_row: 4,
  margin: 1,
  lazy_load: true,
  on_error: console.error
};

export const PortfolioPage = () => {
  useEffect(() => {
    console.log("Setting up feed")
    console.log(document.getElementById("portfolio-page-insta-feed"));
    new InstagramFeed({
      ...defaultInstaOptions,
      container: document.getElementById("portfolio-page-insta-feed"),
      callback: ()=>{console.log("Insta feed done")}
    });
  }, []);
  return (
    <>
      <div className="loading-spinner-wrapper">
        <svg className="loading-spinner">
          <circle cx="50%" cy="50%" r="45%" className="loader-circle"></circle>
        </svg>
      </div>
      <div className="adjustable-block portfolio-wrapper">
        <div id="portfolio-page-insta-feed"></div>
        <div></div>
        <a
          className="site-link"
          href="https://www.instagram.com/maggiemae.photo"
          target="_blank"
          rel="noreferrer"
        >
          <h4>See More</h4>
        </a>
        <div></div>
      </div>
    </>
  );
};
