import danBaileyBed from "../assets/dan_bailey_bed.jpg";
import mitchellFamily from "../assets/mitchell_family.jpg";
import abbyJonathanWedding from "../assets/abby_jonathan_wedding.jpg";
import jesseDress from "../assets/DSC_4344.jpg";
import kaylaJohnMaternity from "../assets/kayla_john_maternity.jpg";
import maggieHeadshot from "../assets/maggie_headshot.jpg";
import weddingPartyWideshot from "../assets/wedding-party-wideshot.jpg";

import "../styles/landing-page.scss";
import { RouterLinkButton } from "../components/RouterLinkButton";
import { ROUTES } from "../routes";

export const LandingPage = () => (
  <div className="body-container">
    <div className="block col-sm-12 window-resized-block">
      <div className="image-container" style={{ alignItems: "flex-start" }}>
        <img
          src={danBaileyBed}
          alt="jumping on bed"
          className="dark-filter banner-image"
        />
      </div>
      <div className="content-container">
        <div className="large-text-overlay">
          <h1>Maggie Mae Design</h1>
        </div>
      </div>
    </div>

    <div className="block container-fluid">
      <div className="row justify-content-center h-100">
        <div className="col-sm-6 h-100 content-padding">
          <div>
            <h1></h1>
            <h4></h4>
            <p>
            </p>
            <RouterLinkButton to={ROUTES.contact} className="primary-button">
              Contact Me
            </RouterLinkButton>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="image-container ">
            <img
              src={mitchellFamily}
              alt="couple with baby"
              style={{ marginTop: "8%" }}
            />
          </div>
        </div>
      </div>
    </div>
    <div className="block container-fluid vert-center three-column-image-container">
      <div className="col-sm">
        <div className="image-container vert-center no-overflow">
          <img
            className="abby-jonathan-wedding"
            src={abbyJonathanWedding}
            alt="wedding day kiss"
          />
        </div>
      </div>
      <div className="col-sm">
        <div className="image-container vert-center no-overflow">
          <img
            className="jesse-dress"
            src={jesseDress}
            alt="jesse in a dress"
          />
        </div>
      </div>
      <div className="col-sm hidden-mobile">
        <div className="image-container vert-center no-overflow">
          <img
            className="couple-maternity"
            src={kaylaJohnMaternity}
            alt="couple maternity pics"
          />
        </div>
      </div>
    </div>

    <div className="container-fluid">
      <div className="row justify-content-center about-container">
        <div className="image-container ">
          <img src={maggieHeadshot} alt="Maggie Francis Headshot" />
        </div>
        <div className="vert-center" style={{ padding: "2% 0% 0 8%" }}>
          <div>
            <h1>About Me</h1>
            <h4></h4>
            <p>
            </p>
            <a href="about.html" className="site-link">
              <h4>More about me ></h4>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div className="block col-sm-12" style={{ margin: 0, padding: 0 }}>
      <div className="image-container">
        <img
          src={weddingPartyWideshot}
          alt="wedding party sparklers"
          className="extra-dark-filter wedding-party-pic"
        />
        <div className="centered-text-wrapper">
          <div className="centered-text-overlay">
            <h3></h3>
          </div>
        </div>
      </div>
    </div>
  </div>
);
