import './styles/styles.scss'
import { LandingPage } from './pages/Landing.page'
import { Navbar } from './components/Navbar'
import { Footer } from './components/Footer'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { ROUTES } from './routes'
import { PhotographyPage } from './pages/Photography.page'
import { AboutPage } from './pages/About.page'
import { PortfolioPage } from './pages/Portfolio.page'
import { ContactPage } from './pages/Contact.page'
// Init Email Service
import { init } from 'emailjs-com'
init('user_s2rDXXNUQPyheQJH9F6Wa')

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path={ROUTES.photography} element={<PhotographyPage />} />
        <Route path={ROUTES.about} element={<AboutPage />} />
        <Route path={ROUTES.portfolio} element={<PortfolioPage />} />
        <Route path={ROUTES.contact} element={<ContactPage />} />
        <Route path={ROUTES.home} element={<LandingPage />} />
      </Routes>
      <Footer />
    </Router>
  )
}

export default App
