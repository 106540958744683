import { useNavigate } from 'react-router-dom'

export const RouterLinkButton = ({ ...rest }) => {
  const navigate = useNavigate()
  return (
    <button
      {...rest} // `children` is just another prop!
      onClick={(event) => {
        navigate(rest.to)
      }}
    >
      {rest.children}
    </button>
  )
}
