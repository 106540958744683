import "../styles/footer.scss";
import { Link } from "react-router-dom";
import { ROUTES } from "../routes";
// import InstagramFeed from "instagram-feed";
//
// const footerOptions = {
//   username: "maggiemae.photo",
//   display_profile: false,
//   display_biography: false,
//   display_gallery: true,
//   display_captions: false,
//   max_tries: 8,
//   callback: () => {},
//   styling: true,
//   items: 6,
//   items_per_row: 6,
//   margin: 1,
//   lazy_load: true,
//   on_error: console.error
// };
//
// const updateInstaFeedFooter = (options = {}) =>
//   new InstagramFeed({ ...footerOptions, ...options });

export const Footer = () => {
  // useEffect(() => {
  //   const container = document.getElementById("footer-insta-feed");
  // updateInstaFeedFooter({ container });
  // }, []);
  return (
    <footer>
      <div className="footer-container">
        <h3>Let's Be Insta-Friends</h3>
        <hr className="tiny-hr" />
        <a href="https://www.instagram.com/maggiemae.photo/">
          {" "}
          @maggiemae.photo{" "}
        </a>
        <div id="footer-insta-feed"></div>
        <div className="footer-bottom-container">
          <div className="footer-site-map">
            <Link to={ROUTES.home}>home </Link> |
            <Link to={ROUTES.photography}> photography </Link> |
            <Link to={ROUTES.about}> about me </Link> |
            <Link to={ROUTES.portfolio}> portfolio </Link> |
            <Link to={ROUTES.contact}> contact me</Link>
          </div>
          <div>©maggiemaedesign</div>
        </div>
      </div>
    </footer>
  );
};
